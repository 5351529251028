<!-- eslint-disable no-console -->
<script setup lang="ts">
import type { Video } from "~/api/video"
import type { VideoJsPlayer } from "video.js"

import { computed, ref, onMounted } from "vue"
import { useEventListener } from "@vueuse/core"

import trackEvent from "~/api/track-event"
import VideoPlayer from "~/components/VideoPlayer.vue"
import CalendarEmbed from "~/components/CalendarEmbed.vue"
import { supportsVideoType } from "~/utils/codec-support"

const video: Video = __PAGE_STATE__
const locale = __PAGE_LOCALE__

const videoPlayerElement = ref<typeof VideoPlayer | null>()
const player = computed<VideoJsPlayer | null>(
    () => videoPlayerElement.value?.player ?? null,
)
const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search)
    const queryParams: Record<string, string> = {}
    params.forEach((value, key) => {
        queryParams[key] = value
    })
    return queryParams
}
const parseColorSettings = (params: Record<string, string>) => {
    return {
        backgroundColor: params.backgroundColor || null,
        textColor: params.textColor || null,
        ctaButtonColor: params.ctaButtonColor || null,
        ctaTextColor: params.ctaTextColor || null,
        creatorTextColor: params.creatorTextColor || null,
    }
}
const queryParams = getQueryParams()
const colorSettings = parseColorSettings(queryParams)
const DEFAULT_COLORS = {
    backgroundColor: "#111827",
    textColor: "#FFFFFF",
    ctaButtonColor: "#EF4444",
    ctaTextColor: "#FFFFFF",
    creatorTextColor: "#9CA3AF",
}
const colors = ref({
    backgroundColor:
        colorSettings.backgroundColor ||
        video.landingPageSettings?.backgroundColor ||
        DEFAULT_COLORS.backgroundColor,
    textColor:
        colorSettings.textColor ||
        video.landingPageSettings?.textColor ||
        DEFAULT_COLORS.textColor,
    ctaButtonColor:
        colorSettings.ctaButtonColor ||
        video.landingPageSettings?.ctaButtonColor ||
        DEFAULT_COLORS.ctaButtonColor,
    ctaTextColor:
        colorSettings.ctaTextColor ||
        video.landingPageSettings?.ctaTextColor ||
        DEFAULT_COLORS.ctaTextColor,
    creatorTextColor:
        colorSettings.creatorTextColor ||
        video.landingPageSettings?.creatorTextColor ||
        DEFAULT_COLORS.creatorTextColor,
})

// track page loads when the page actually loads and executes JavaScript
onMounted(() => {
    trackEvent("load", player.value)

    // set backgroundcolor on body
    document.body.style.backgroundColor = colors.value.backgroundColor

    if (video.landingPageCustomHTML) {
        document.body.insertAdjacentHTML(
            "beforeend",
            video.landingPageCustomHTML,
        )
    }
})

const started = ref(false)
const ended = ref(false)

const start = async () => {
    player.value!.currentTime(0)
    player.value!.play()
    ended.value = false
}

const onPlay = () => {
    if (player.value?.paused()) return

    if (started.value) {
        trackEvent("resume", player.value)
        return
    }
    trackEvent("start", player.value)
    started.value = true
}
const onPause = () => {
    if (started.value && !ended.value) trackEvent("pause", player.value)
}
const onEnded = () => {
    started.value = false
    ended.value = true
    trackEvent("finish", player.value)
}

useEventListener("beforeunload", () => trackEvent("close", player.value))

const bookCallSection = ref<any>(null)
defineExpose({
    bookCallSection,
})

const videoJsOptions = computed(() => {
    let sources

    if (supportsVideoType("webm") === "probably") {
        sources = [
            {
                src: video.videoStorageUrlWEBM,
                type: "video/webm",
            },
            {
                src: video.videoStorageUrlMP4,
                type: "video/mp4",
            },
        ]
    } else {
        sources = [
            {
                src: video.videoStorageUrlMP4,
                type: "video/mp4",
            },
            {
                src: video.videoStorageUrlWEBM,
                type: "video/webm",
            },
        ]
    }

    sources = sources.filter((source) => !!source.src)

    return {
        sources,
        controls: true,
        fluid: true,
        autoSetup: false,
        responsive: true,
        bigPlayButton: false,
        // retryOnError: true,  // we handle retries ourselves...
        playbackRates: [1, 1.25, 1.5, 1.75, 2, 2.5],
        defaultPlaybackRate: 1,
        preload: "auto",
        poster: video.thumbnailStorageUrl,
    }
})

const videoStartedLoading = ref(false)
const videoCanAutoPlay = ref(true)

async function onReady() {
    videoStartedLoading.value = true
    player.value?.poster("")
    player.value?.currentTime(1)

    try {
        await player.value?.play()
    } catch (unmutedError) {
        console.warn(unmutedError)
        videoCanAutoPlay.value = false
    }
}

const calendarEnabled = computed(() => !!video.calendarEmbed?.enabled)
</script>

<template>
    <div
        class="min-h-screen sm:p-6 md:p-12 flex flex-col items-stretch sm:justify-center"
        :style="{ backgroundColor: colors.backgroundColor }"
        :class="{
            'justify-start': !calendarEnabled,
        }"
    >
        <div
            class="page-container mx-auto grid grid-cols-1 gap-x-4 xl:gap-x-8 gap-y-10 w-full sm:h-auto"
            :class="{
                'lg:grid-cols-video-page-horizontal': calendarEnabled,
            }"
        >
            <!-- Player section -->
            <div
                class="mt-16 sm:mt-0 flex flex-col items-stretch justify-center"
            >
                <div
                    class="relative bg-black sm:rounded-md sm:shadow-md group aspect-video"
                >
                    <VideoPlayer
                        ref="videoPlayerElement"
                        :options="videoJsOptions"
                        @play="onPlay"
                        @pause="onPause"
                        @ended="onEnded"
                        @loadedmetadata="onReady"
                    />

                    <!-- Start overlay -->
                    <transition
                        class="transition transform duration-200"
                        leave-to-class="opacity-0 scale-125"
                        enter-from-class="opacity-0 scale-125"
                    >
                        <div
                            v-if="!videoCanAutoPlay && !started && !ended"
                            class="absolute inset-0 cursor-pointer flex flex-col content-center"
                            @click="start()"
                        >
                            <div
                                class="m-auto w-24 h-24 bg-red-500 rounded-full shadow-lg transform group-hover:scale-105 transition"
                            >
                                <ic-round-play-arrow class="m-auto w-24 h-24" />
                            </div>
                        </div>
                    </transition>
                    <!-- End overlay -->

                    <transition
                        class="transition transform duration-200"
                        leave-to-class="opacity-0 scale-125"
                        enter-from-class="opacity-0 scale-125"
                    >
                        <div
                            v-if="ended"
                            class="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-70 sm:p-8 md:p-12 lg:p-16"
                        >
                            <div class="flex sm:flex-1">
                                <div class="m-auto flex flex-col items-center">
                                    <h1
                                        class="text-2xl sm:text-3xl font-bold"
                                        :style="{ color: colors.textColor }"
                                    >
                                        {{ video.title }}
                                    </h1>
                                    <h2
                                        v-if="video.creator"
                                        class="mt-2 text-gray-200"
                                        :style="{ color: colors.textColor }"
                                    >
                                        {{ locale.createdBy }}
                                        <strong
                                            :style="{
                                                color: colors.creatorTextColor,
                                            }"
                                        >{{ video.creator }}</strong>
                                    </h2>
                                    <a
                                        v-if="video.cta"
                                        :href="video.cta.url"
                                        target="_blank"
                                        :style="{
                                            color: colors.ctaTextColor,
                                            backgroundColor:
                                                colors.ctaButtonColor,
                                        }"
                                        class="mt-6 md:mt-8 rounded-md font-bold py-3 px-4 shadow-md flex items-center justify-center bg-red-500 transition hover:bg-red-400 active:bg-red-600 focus:outline-none focus-visible:ring-2 ring-red-500 ring-opacity-40"
                                        @click="
                                            () => {
                                                trackEvent('interact', player)
                                            }
                                        "
                                    >
                                        {{ video.cta.label }}
                                    </a>
                                </div>
                            </div>
                            <button
                                class="mt-6 flex items-center font-semibold py-2 px-3 rounded-md bg-white bg-opacity-20 text-white text-opacity-90 transition hover:text-opacity-100 hover:bg-opacity-30 active:bg-opacity-20 focus:outline-none focus-visible:ring-2 ring-white ring-opacity-10"
                                @click="start()"
                            >
                                <heroicons-outline-refresh
                                    class="mr-3 w-6 h-6"
                                />
                                {{ locale.watchAgain }}
                            </button>
                        </div>
                    </transition>
                </div>
                <div
                    class="flex flex-row items-center justify-between mt-10 gap-x-2 gap-y-8 px-4 lg:px-0"
                >
                    <!-- Metadata section -->
                    <div class="flex-1 w-full">
                        <div
                            class="text-2xl font-bold"
                            :style="{ color: colors.textColor }"
                        >
                            {{ video.title }}
                        </div>
                        <div
                            v-if="video.creator"
                            class="mt-1 text-gray-400"
                            :style="{ color: colors.textColor }"
                        >
                            {{ locale.createdBy }}
                            <strong
                                :style="{ color: colors.creatorTextColor }"
                            >{{ video.creator }}</strong>
                        </div>
                    </div>
                    <div
                        v-if="video?.cta"
                        class="sm:mt-0 flex justify-end items-center"
                    >
                        <a
                            :href="video.cta.url"
                            target="_blank"
                            class="rounded-md font-bold py-3 px-4 shadow-md transition focus:outline-none focus-visible:ring-2 ring-red-500 ring-opacity-40"
                            :style="{
                                color: colors.ctaTextColor,
                                backgroundColor: colors.ctaButtonColor,
                            }"
                            :class="
                                ended
                                    ? 'bg-gray-500 hover:bg-gray-400 active:bg-gray-600 ring-gray-500'
                                    : 'bg-red-500 hover:bg-red-400 active:bg-red-600 ring-red-500'
                            "
                            @click="
                                () => {
                                    trackEvent('interact', player)
                                }
                            "
                        >
                            {{ video.cta.label }}
                        </a>
                    </div>
                    <!-- Metadata sends -->
                </div>
            </div>

            <div
                v-if="calendarEnabled"
                class="min-h-[700px]"
            >
                <CalendarEmbed
                    v-if="videoStartedLoading && calendarEnabled"
                    :video="video"
                    :player="player"
                />
            </div>
        </div>
    </div>
</template>

<style>
.page-container {
    /* Make sure that page contents don't overflow vertically if possible */
    max-width: clamp(
        theme("screens.md"),
        calc((100vh - 170px) * (16 / 9)),
        theme("screens.2xl")
    );
}
</style>
