import type { AxiosError } from "axios"
import type { Video } from "~/api/video"
import type { VideoJsPlayer } from "video.js"
import axios from "axios"
import axiosRetry from "axios-retry"
import { datadogRum } from "@datadog/browser-rum"
import * as Sentry from "@sentry/browser"

const video: Video = __PAGE_STATE__
const api = axios.create({})
axiosRetry(api, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) =>
        axiosRetry.isRetryableError(err) || axiosRetry.isNetworkError(err), // retry all HTTP methods
})

// The `dnt` query parameter allows us to share example videos with people
// interested in our product without generating bogus analytics events.
const doNotTrack = new URLSearchParams(location.search).get("dnt") === "1"
// eslint-disable-next-line no-console
if (doNotTrack) console.log('"dnt" query parameter found, disabling tracking.')
datadogRum.addRumGlobalContext("doNotTrack", doNotTrack)
Sentry.setContext("doNotTrack", { doNotTrack })

export default async function trackEvent(
    type: string,
    player: VideoJsPlayer | null,
) {
    const currentTime = player.currentTime() ?? 0

    // check if we're in an iframe, if so, send the event to the parent
    if (window.self !== window.top) {
        try {
            window.parent.postMessage(
                {
                    source: "pitchlane-viewer",
                    event: type,
                    video,
                    currentTime,
                },
                "*",
            )
        } catch (err) {
            console.error("window.postMessage error", err)
        }
    }

    if (doNotTrack) return

    try {
        await api.post(`/api/videos/${video.shortId}/count/${type}`, {
            currentTime,
        })
    } catch (err) {
        console.error(
            "trackEvent error",
            (err as AxiosError)?.response?.data ?? err,
        )
    }
}
