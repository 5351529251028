<template>
    <div class="min-h-screen flex bg-gray-900 text-white">
        <div class="m-auto flex flex-col items-center text-center">
            <ant-design-key-outlined class="w-24 h-24 text-red-500" />
            <div class="mt-4 text-2xl leading-relaxed font-mono">
                <strong>Cliplet</strong> is currently<br />in
                <em>closed beta</em>.
            </div>
            <div class="mt-4 text-gray-300 space-y-1">
                <p>Interested in testing?</p>
                <p>
                    <a
                        class="text-red-500 hover:text-red-400 transition font-bold"
                        href="https://pitchlane.com"
                        rel="noopener"
                    >Contact us</a>.
                </p>
            </div>
        </div>
    </div>
</template>
