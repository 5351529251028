import { createApp } from "vue"
import * as Sentry from "@sentry/browser"
import { CaptureConsole } from "@sentry/integrations"
import { datadogRum } from "@datadog/browser-rum"
import "virtual:vite-plugin-sentry/sentry-config"

const video = __PAGE_STATE__
const locale = __PAGE_LOCALE__
const context = {
    video,
    campaignId: video?.campaignId,
    locale,
}

if (__ENV__.SENTRY_DSN) {
    const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
    const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release
    Sentry.init({
        dsn: __ENV__.SENTRY_DSN,
        environment: __ENV__.SENTRY_ENV,
        ignoreErrors: [
            /Object Not Found Matching Id/,
            "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
        ],
        denyUrls: [
            /^chrome-extension:\//,
            /safari-web-extension/,
            /servicebell\.com/,
        ],
        dist,
        release,
        integrations: [
            new CaptureConsole({
                levels: ["errors"],
            }),
        ],
    })
    Sentry.setContext("context", context)
}

if (__ENV__.SENTRY_ENV) {
    const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release
    datadogRum.init({
        applicationId: "f08cabfd-dcc9-4bf7-ad07-1e761d9e4df2",
        clientToken: "puba8036249178d577e544e2f78e9153d91",
        site: "datadoghq.eu",
        service: "pitchlane-viewer",
        version: release,
        env: __ENV__.SENTRY_ENV,
        sampleRate: 100,
        sessionReplaySampleRate: 100, // todo: bring this number down eventually
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        defaultPrivacyLevel: "mask-user-input",
        excludedActivityUrls: [
            /^chrome-extension:\//,
            /safari-web-extension/,
            /servicebell\.com/,
        ],
    })
    datadogRum.setGlobalContext(context)
    datadogRum.setRumGlobalContext(context)
    datadogRum.startSessionReplayRecording()
}

import indexView from "~/views/index.vue"
import videoView from "~/views/video.vue"
const views = { index: indexView, video: videoView }

const component = (views as any)[__PAGE_VIEW__]
const app = createApp(component)
app.mount("#app")
