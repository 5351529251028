<script setup lang="ts">
import type { Video } from "~/api/video"
import type { VideoJsPlayer } from "video.js"
import { computed, ref, onMounted } from "vue"
import { useEventListener } from "@vueuse/core"
import trackEvent from "~/api/track-event"

const props = defineProps<{
    video: Video
    player: VideoJsPlayer | null
}>()

const calendarType = computed(
    () => props.video?.calendarEmbed?.type?.toLowerCase(),
)
const calendarUrl = computed(() => props.video?.calendarEmbed?.url)

const calendarLoader = ref<boolean>(true)
const calendarVisible = ref<boolean>(false)

function hideLoaderIfVisible() {
    calendarLoader.value = false
    calendarVisible.value = true
}

function loadIframe() {
    hideLoaderIfVisible()
}

function setupCalendly() {
    const theWindow = window as any
    setTimeout(() => {
        if (!theWindow.Calendly) return setupCalendly()

        theWindow.Calendly.initInlineWidget({
            url: calendarUrl.value,
            parentElement: document.getElementById("calendly"),
            resize: false,
        })
    }, 100)
}

if (
    props.video.calendarEmbed?.enabled &&
    props.video.calendarEmbed?.type === "calendly"
)
    setupCalendly()

function setupSavvycal() {
    const theWindow = window as any

    setTimeout(() => {
        if (!theWindow.SavvyCal) return setupSavvycal()

        theWindow.SavvyCal("init")
        theWindow.SavvyCal("inline", {
            link: calendarUrl.value.replace(/https?:\/\/savvycal.com\/?/g, ""),
            selector: "#savvycal",
        })
    }, 100)
}

if (
    props.video.calendarEmbed?.enabled &&
    props.video.calendarEmbed?.type === "savvycal"
)
    setupSavvycal()

useEventListener("message", (e) => {
    // handle Calendly loaded
    if (e.data.event === "calendly.event_type_viewed") {
        hideLoaderIfVisible()
    }

    // handle Calendly event scheduled
    if (e.data.event && e.data.event === "calendly.event_scheduled")
        trackEvent("calendar_booking", props.player)
})

useEventListener("savvycal.scheduled" as any, () =>
    trackEvent("calendar_booking", props.player),
)

const hubspotUrl = computed(() => {
    const url = new URL(calendarUrl.value)
    const query = new URLSearchParams(url.search)
    query.set("embed", "true")
    return `${url.origin}${url.pathname}?${query.toString()}`
})

const LOAD_TIMEOUT = 5000

onMounted(() => {
    // global backup plan - if it takes too long, just hide the loader
    setTimeout(() => {
        hideLoaderIfVisible()
    }, LOAD_TIMEOUT)
})
</script>
<template>
    <div
        v-if="video?.calendarEmbed?.enabled"
        ref="bookCallSection"
        class="h-full overflow-x-hidden"
    >
        <div class="relative h-full">
            <div
                class="absolute h-full w-full flex flex-col items-stretch justify-items-stretch"
            >
                <div
                    v-if="calendarType === 'savvycal'"
                    class="w-full h-full"
                >
                    <div
                        id="savvycal"
                        :style="
                            calendarVisible
                                ? { visibility: 'visible' }
                                : { visibility: 'hidden' }
                        "
                    ></div>
                    <component :is="'script'">
                        window.SavvyCal=window.SavvyCal||function(){(SavvyCal.q=SavvyCal.q||[]).push(arguments)};
                    </component>
                    <component
                        :is="'script'"
                        async
                        src="https://embed.savvycal.com/v1/embed.js"
                    ></component>
                </div>
                <div v-else-if="calendarType === 'calendly'">
                    <div
                        id="calendly"
                        style="height: 0px; width: 100%; opacity: 0"
                        :style="
                            calendarVisible
                                ? { opacity: 1, height: '800px' }
                                : undefined
                        "
                    ></div>
                    <component
                        :is="'script'"
                        type="text/javascript"
                        src="https://assets.calendly.com/assets/external/widget.js"
                        async
                    >
                    </component>
                </div>
                <div v-else-if="calendarType === 'zcal'">
                    <component
                        :is="'script'"
                        type="text/javascript"
                        src="https://static.zcal.co/embed/v1/embed.js"
                        async
                    >
                    </component>
                    <div class="zcal-inline-widget">
                        <a :href="calendarUrl"></a>
                    </div>
                </div>
                <div v-else-if="calendarType === 'hubspot'">
                    <div
                        class="meetings-iframe-container"
                        :data-src="hubspotUrl"
                    ></div>
                    <component
                        :is="'script'"
                        type="text/javascript"
                        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                        async
                    >
                    </component>
                </div>
                <div v-else>
                    <iframe
                        :src="calendarUrl"
                        :style="
                            !calendarLoader
                                ? { visibility: 'visible', height: '1000px' }
                                : { visibility: 'hidden', height: '1000px' }
                        "
                        style="width: 100%"
                        @load="loadIframe"
                    ></iframe>
                </div>
            </div>
            <div
                v-show="calendarLoader"
                class="absolute top-0 left-0 right-0 h-full flex justify-center items-center text-center"
            >
                <div
                    class="inline-block border-green-600 border-r-0 animate-spin w-20 h-20 border-4 rounded-full"
                    role="status"
                >
                    <span class="hidden">.</span>
                </div>
            </div>
        </div>
    </div>
</template>
