<script setup lang="ts">
import videojs from "video.js"
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue"
import "video.js/dist/video-js.min.css"

const props = defineProps<{
    options: Record<string, any>
}>()

const sourceIndex = ref(0)
const options = computed(() => {
    const { sources, ...options } = props.options
    const newOptions = {
        ...options,
        sources,
        src: sources[sourceIndex.value].src,
    }
    return newOptions
})

const videoPlayer = ref<HTMLVideoElement | null>(null)
const player = ref<videojs.Player | null>(null)
defineExpose({ player })
const emit = defineEmits(["ready", "play", "pause", "ended", "loadedmetadata"])

function setupPlayer() {
    videojs.log.level("off")
    player.value = videojs(videoPlayer.value!, options.value, () => {
        emit("ready")
    })
    player.value.on("error", (event) => {
        const error = player.value?.error()
        if (!error) return

        if (error.code === 4) {
            // eslint-disable-next-line no-console
            console.warn(
                "Trying next source... MEDIA_ERR_SRC_NOT_SUPPORTED",
                error,
            )
            sourceIndex.value =
                sourceIndex.value + (1 % props.options.sources.length)
            player.value?.dispose()
            player.value = null
            setupPlayer()
        } else {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    })

    const defaultPlaybackRate = (options.value as any).defaultPlaybackRate
    if (defaultPlaybackRate) {
        player.value.defaultPlaybackRate(defaultPlaybackRate)
        player.value.playbackRate(defaultPlaybackRate)
    }

    player.value.on("play", (...args: any[]) => emit("play", ...args))
    player.value.on("pause", (...args: any[]) => emit("pause", ...args))
    player.value.on("ended", (...args: any[]) => emit("ended", ...args))
    player.value.on("loadedmetadata", (...args: any[]) =>
        emit("loadedmetadata", ...args),
    )
}

onMounted(() => {
    setupPlayer()
})

watch(
    () => props.options.sources,
    () => {
        sourceIndex.value = 0
    },
    { deep: true },
)

onBeforeUnmount(() => {
    if (player.value) player.value.dispose()
})
</script>
<template>
    <div>
        <video
            ref="videoPlayer"
            class="video-js"
        ></video>
    </div>
</template>
<style>
video[poster] {
    object-fit: cover;
}
.vjs-poster {
    background-size: cover;
    background-position: inherit;
}
</style>
